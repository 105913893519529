import React from "react";
import { Router, useLocation } from "@reach/router";
import { Link } from "gatsby";
import queryString from "query-string";

import { login, logout, isAuthenticated, getProfile } from "../utils/auth";

import Layout from "../components/common/layout/layout";
import SecureNavigation from "../components/common/navigation/secure-navigation";
import Dashboard from "../components/protected/dashboard/dashboard";
import Footer from "../components/sections/footer";

console.log(Dashboard);

const App = () => {
    const location = useLocation();
    const authMode = (location.search && queryString.parse(location.search).mode) || "login";

    if (!isAuthenticated()) {
        login(authMode);
        return <p>Redirecting to login...</p>;
    }

    const user = getProfile();

    return (
        <Layout isDark>
            <SecureNavigation logout={logout} />
            {/* <Router basepath="/app"> */}
            <Router basepath="/app">
                <Dashboard path="/" />
            </Router>
            {/* <Footer /> */}
        </Layout>
    );
};
export default App;
