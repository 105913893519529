import React from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

import { Container } from "../../global";

import Layout from "../../common/layout/layout";
import Navigation from "../../common/navigation/navigation";

import Footer from "../..//sections/footer";

const StyledContainer = styled(Container)`
    ${"" /* margin-top: 100px; */}
    position: relative;
    top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${"" /* background: red; */}

    height: 100%;
`;

const chart = {
    options: {
        chart: {
            background: "#2b2d3d",
        },
        colors: ["#5ac48d"],
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                track: {
                    background: "#3f475d",
                    startAngle: -135,
                    endAngle: 135,
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: true,
                        color: "#f1f0f0",
                        offsetY: 25,
                    },
                    value: {
                        fontSize: "30px",
                        show: true,
                        color: "#f1f0f0",
                        offsetY: -20,
                    },
                    total: {
                        show: false,
                    },
                },
            },
        },
        fill: {
            type: "solid",
            colors: [
                function({ value, seriesIndex, w }) {
                    if (value < 70) {
                        return "#7ad53e";
                    } else if (value >= 70 && value < 90) {
                        return "#d59d3e";
                    } else {
                        return "#d53e3e";
                    }
                },
            ],
            opacity: 1,
        },
        stroke: {
            show: true,
            lineCap: "round",
        },
        labels: ["684 / 1000"],
        title: {
            text: "Usage",
            align: "center",
            floating: true,
            style: {
                fontSize: "18px",
                fontWeight: "500",
                color: "#f1f0f0",
            },
        },
    },
    series: [90],
};

const DashboardPage = () => (
    <StyledContainer>
        <Chart options={chart.options} series={chart.series} type="radialBar" width="280" />
    </StyledContainer>
);

export default DashboardPage;
