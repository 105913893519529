import React, { Component, useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
import { Menu, X } from "react-feather";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import { Container } from "../../global";
import {
    Nav,
    NavItem,
    Brand,
    StyledContainer,
    NavListWrapper,
    MobileMenu,
    Mobile,
    ActionsContainer,
} from "./style";

const NAV_ITEMS = ["Dashboard", "Logs", "Settings"];

// closeMobileMenu
const NavList = ({ mobile = false, closeMobileMenu = () => {} }) => (
    <NavListWrapper mobile={mobile}>
        <Scrollspy
            items={NAV_ITEMS.map(item => item.toLowerCase())}
            currentClassName="active"
            mobile={mobile}
            offset={-64}
        >
            {NAV_ITEMS.map(navItem => (
                <NavItem key={navItem}>
                    <Link
                        to={navItem === NAV_ITEMS[0] ? "/app" : `/app/${navItem.toLowerCase()}`}
                        onClick={closeMobileMenu}
                        getProps={({ isCurrent }) => {
                            return {
                                style: isCurrent
                                    ? { textDecoration: "underline", color: "#cca86e" }
                                    : {},
                            };
                        }}
                    >
                        {navItem}
                    </Link>
                </NavItem>
            ))}
        </Scrollspy>
    </NavListWrapper>
);
const ImageWrapper = styled.div`
    ${"" /* height: 90px; */}
    ${"" /* height: ${props => (props.scrolled ? `65px` : `90px`)};
    overflow: hidden; */}
    display: flex;
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const StyledImage = styled(Img)`
    width: 100px;
    ${"" /* height: ${props => (props.scrolled ? `100px` : `50px`)}; */}
    ${"" /* transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1); */}
`;

const SecureNavigation = ({ logout, ...props }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            file(sourceInstanceName: { eq: "logo" }, name: { eq: "logo_2" }) {
                childImageSharp {
                    fluid(maxWidth: 150) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    useEffect(() => {
        const handleScroll = () => {
            setHasScrolled(window.pageYOffset > 64);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setHasScrolled]);

    const toggleMobileMenu = () => setMobileMenuOpen(prevState => !prevState);
    const closeMobileMenu = () => {
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    };

    return (
        <Nav {...props} scrolled={hasScrolled}>
            <StyledContainer>
                {/* <Brand> */}
                <StyledImage scrolled={hasScrolled} fluid={data.file.childImageSharp.fluid} />
                {/* <Scrollspy offset={-64} item={["top"]} currentClassName="active">
                        <AnchorLink href="#top" onClick={closeMobileMenu}>
                            <ImageWrapper scrolled={hasScrolled}>
                                <Img
                                    scrolled={hasScrolled}
                                    fluid={data.file.childImageSharp.fluid}
                                />
                            </ImageWrapper>
                        </AnchorLink>
                    </Scrollspy> */}
                {/* </Brand> */}
                <Mobile>
                    <button
                        onClick={toggleMobileMenu}
                        style={{ color: "black", background: "none" }}
                    >
                        {mobileMenuOpen ? (
                            <X size={24} alt="close menu" />
                        ) : (
                            <Menu size={24} alt="open menu" />
                        )}
                    </button>
                </Mobile>

                <Mobile hide>
                    <NavList mobile={false} closeMobileMenu={closeMobileMenu} />
                </Mobile>
                <ActionsContainer>
                    <button onClick={logout}>Log Out</button>
                </ActionsContainer>
            </StyledContainer>
            <Mobile>
                {mobileMenuOpen && (
                    <MobileMenu>
                        <Container>
                            <NavList mobile closeMobileMenu={closeMobileMenu} />
                        </Container>
                    </MobileMenu>
                )}
            </Mobile>
        </Nav>
    );
};

export default SecureNavigation;
